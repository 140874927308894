import React, { useEffect, useMemo, useRef, useState } from "react";
import { useThread } from "./hooks/useThread";
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import Navbar from "./components/Navbar";
import AttachFileIcon from "./assets/attachFile.svg";
import DeleteIcon from './assets/delete.svg';
import SendIcon from './assets/send.svg';
import EmptyChat from "./components/EmptyChat";

function App() {
    const [run, setRun] = useState(undefined);
    const { messages, isSubmittingMessage, files, isGeneratingResponse, stopGeneratingResponse, clearThread, postMessage } = useThread(run, setRun);

    const [fileData, setFileData] = useState(null);
    const [message, setMessage] = useState(null);
    const chatInputRef = useRef(undefined);

    const handleFileInputChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setFileData(file);
    };

    const handleButtonClick = () => {
        if (isGeneratingResponse) {
            stopGeneratingResponse();
        } else {
            const message = document.getElementById('messageInput').value;

            // Clear the message input and file data
            document.getElementById('messageInput').value = '';
            setFileData('');
            setMessage('');

            postMessage(message, fileData, setRun);
        }
    };

    const renderMessage = (msg) => {
        const { object } = msg;

        if (object === 'thread.run.step' && ["failed", "cancelled"].includes(msg.status)) {
            return (
                <div className="step-error">
                    {msg.status === "failed" ? (
                        <>
                            <i class="fa fa-exclamation-circle" aria-hidden="true" style={{ fontSize: 16 }}></i> 
                            <span>Run failed</span> {msg.last_error?.message}
                        </>
                    ) : (
                        <>
                            <i class="fa fa-stop-circle" aria-hidden="true" style={{ fontSize: 16 }}></i> 
                            <span>Run cancelled</span>
                        </>
                    )}
                </div>
            );
        }
        if (object === 'thread.message') {
            return (
                <Markdown remarkPlugins={[remarkGfm]}>
                    {msg.content?.[0]?.text?.value}
                </Markdown>
            );
        }
        return null;
    };

    const renderMessageFiles = (msg) => {
        return msg.file_ids?.map((fileId) => {
            const fileObject = files.find((file) => file.id === fileId);
            return <div className="message-file"><i class="fa fa-file-o" aria-hidden="true"></i> <b>{fileObject?.filename ?? fileId}</b></div>
        });
    }

    const isSendDisabled = useMemo(() => {
        return isSubmittingMessage || (!isGeneratingResponse && !message && !fileData);
    }, [fileData, isGeneratingResponse, isSubmittingMessage, message]);

    useEffect(() => {
        document.getElementById(messages[messages.length - 1]?.id)?.scrollIntoView();
    }, [messages, isSubmittingMessage]);

    useEffect(() => {
        if (chatInputRef.current) {
            chatInputRef.current.style.height = '0px'; // Reset height
            chatInputRef.current.style.height = `${chatInputRef.current.scrollHeight}px`;
        }
    }, [message]);

    return (
        <div className="container">
            <Navbar
                actionButtons={[
                    {
                        isDisabled: isSubmittingMessage || isGeneratingResponse,
                        onClick: clearThread,
                        label: "Clear conversation",
                        icon: <img src={DeleteIcon} alt="Clear conversation" width="24" height="24" />,
                    },
                ]}
            />
            <div className="chat-container" id="chat">
                {messages.length > 0 ? (
                    <div
                        className="chat-messages"
                        style={{
                            height: `calc(100vh - ${document.getElementById('message').offsetHeight}px - 75px)`
                        }}
                    >
                        {messages.map((msg, index) => (
                            <div
                                key={index}
                                className={`chat-message ${msg.role}`}
                            >
                                {renderMessage(msg)}
                                <div>
                                    {renderMessageFiles(msg)}
                                </div>
                                <div id={msg.id}></div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <EmptyChat />
                )}
            </div>
            <div className="message-wrapper" id="message">
                <div className="message-container">
                    <div className="message-input-container">
                        <textarea
                            ref={chatInputRef}
                            disabled={isGeneratingResponse || isSubmittingMessage}
                            id="messageInput" className="message-input"
                            placeholder="Type message"
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <button disabled={isGeneratingResponse || isSubmittingMessage} className="file-upload-button" onClick={() => document.getElementById('fileInput').click()}>
                            <img src={AttachFileIcon} alt="attach file" />
                        </button>
                        <div className="file-overlay">
                            {fileData && (
                                <div className="selected-file">
                                    <i class="fa fa-file-o" aria-hidden="true"></i>
                                    {fileData.name}
                                </div>
                            )}
                        </div>
                    </div>
                    <input
                        type="file"
                        id="fileInput"
                        className="file-input"
                        onChange={handleFileInputChange}
                        accept=".pdf, .doc, .docx"
                    />
                    <button
                        key={isGeneratingResponse}
                        disabled={isSendDisabled}
                        className={`submit-button ${isGeneratingResponse ? 'generating-button' : ''}`}
                        onClick={handleButtonClick}
                    >
                        {isSubmittingMessage ? (
                            <i class="fa fa-spinner fa-spin" style={{ fontSize: 36, color: "#48184C" }}></i>
                        ) : (
                            isGeneratingResponse ? (
                                <i class="fa fa-stop-circle" style={{ fontSize: 36, color: "#48184C" }}></i>
                            ) : (
                                <div className="send-button">
                                    <img src={SendIcon} alt="attach file" width="18" height="18" />
                                    Send
                                </div>
                            )
                        )}
                    </button>
                </div>
                <div className="footnote">
                    Note: Aquila is provided for informational purposes and is not a substitute for legal counsel. All rights reserved by Lexagle. <br />
                    For more information, visit us at lexagle.com.
                </div>
            </div>
        </div>
    );
}

export default App;
