import React from 'react';
import LexagleLogo from '../../assets/logo.svg';
import './styles.css';

function EmptyChat() {
    return (
        <div className="empty-chat-container">
            <h1>Chat with Aquila, our AI</h1>
            <p className="subtext">Aquila helps you unpack your agreements and cut through the legal jargon.</p>
            <img src={LexagleLogo} alt="Aquila" width="180" height="172" />
        </div>
    );
}

export default EmptyChat;
