import { useState, useEffect, useRef } from 'react';
import { cancelRun, createMessage, retrieveAllThreadRunSteps, retrieveAssistantsFiles, retrieveRunSteps, retrieveThreadMessages } from '../api/openai';
import { uniqBy } from 'lodash';

export const useThread = (run, setRun) => {
    const [isCreatingThread, setIsCreatingThread] = useState(false);
    const [threadId, setThreadId] = useState(undefined);
    const [thread, setThread] = useState(undefined);
    const [actionMessages, setActionMessages] = useState([]);
    const [runSteps, setRunSteps] = useState([]);
    const [messages, setMessages] = useState([]);
    const streamedMessages = useRef(null);
    const [isSubmittingMessage, setIsSubmittingMessage] = useState(false);
    const [isGeneratingResponse, setIsGeneratingResponse] = useState(false);
    const runId = useRef(run?.id ?? run?.run_id);

    const [files, setFiles] = useState([]);

    useEffect(() => {
        if (threadId === undefined) {
            const localThreadId = localStorage.getItem("thread_id");
            if (localThreadId) {
                setThreadId(localThreadId);
                retrieveThreadMessages(localThreadId).then(setThread);
                retrieveAssistantsFiles().then(setFiles);
                retrieveAllThreadRunSteps(localThreadId).then(setRunSteps);
            }
        }
    }, [threadId, setThreadId, setThread, isCreatingThread]);

    // This hook is responsible for transforming the thread into a list of messages
    useEffect(() => {
        if (!thread) {
            return;
        }

        const failedSteps = runSteps
            .filter((step) => step.status === "cancelled" || step.status === "failed")
            .map((step) => ({ ...step, created_at: step.failed_at ?? step.cancelled_at }));
        let newMessages = uniqBy([...(streamedMessages?.current ?? thread.data), ...actionMessages, ...failedSteps]
            .sort((a, b) => a.created_at - b.created_at)
            .filter((message) => message.hidden !== true), 'id');
        streamedMessages.current = newMessages;
        setMessages(newMessages);
    }, [thread, actionMessages, runSteps]);

    const clearThread = () => {
        localStorage.removeItem("thread_id");
        setThreadId(undefined);
        setThread(undefined);
        setRun(undefined);
        setMessages([]);
        setActionMessages([]);
        runId.current = undefined;
        setRunSteps([]);
        setIsGeneratingResponse(false);
        setIsSubmittingMessage(false);
    }

    const onResponseDelta = (msgId, delta) => {
        const allMessages = [...streamedMessages.current];
        let currentMsg = allMessages.find((msg) => msg.id === msgId);

        if (currentMsg) {
            currentMsg = delta;
        } else {
            const newMsg = delta;
            allMessages.push(newMsg);
        }

        streamedMessages.current = allMessages;
        setMessages(allMessages);
    };

    const onCreateMessagesSuccess = (newMessages) => {
        if (newMessages.find((newMessage) => (newMessage.file_ids ?? []).length > 0)) {
            // retrieve all files - TO ENHANCE
            retrieveAssistantsFiles().then(setFiles);
        }

        const allMessages = [...streamedMessages.current, ...newMessages];
        streamedMessages.current = allMessages;
        setMessages(allMessages);
    }

    const postMessage = (message, attachment, onSuccessCallback = () => {}) => {
        if (isGeneratingResponse || (!message && !attachment)) return;
        let isNewThread = !threadId;
        if (isNewThread) {
            setIsCreatingThread(true);
            console.log("Creating new thread");
        }
        streamedMessages.current = messages;
        setIsSubmittingMessage(true);
        setIsGeneratingResponse(true);
        createMessage(
            threadId,
            message,
            attachment,
            onResponseDelta,
            onCreateMessagesSuccess,
            (newRun) => { runId.current = newRun.id; },
            (eventData) => {
                setIsGeneratingResponse(false);
                if (eventData?.object === "thread.run.step") {
                    setRunSteps([...runSteps, ...[eventData]]);
                }
            },
        ).then((response) => {
            onSuccessCallback?.(response);
            setRun(response);
            if (isNewThread) {
                setThreadId(response.thread_id);
                setThread(response);
                localStorage.setItem("thread_id", response.thread_id);

            }
        })
        .finally(() => {
            setIsCreatingThread(false);
            setIsSubmittingMessage(false);
        });
    }

    const stopGeneratingResponse = () => {
        if (isGeneratingResponse && runId.current) {
            cancelRun(threadId, runId.current)
                .then(() => {
                    setIsSubmittingMessage(false);
                    return retrieveRunSteps(threadId, runId.current);
                })
                .then((updatedRunSteps) => { console.log('here', updatedRunSteps); setRunSteps([...runSteps, ...updatedRunSteps]) })
                .finally(() => {
                    runId.current = undefined;
                });
        }
    };

    return {
        threadId,
        messages,
        files,
        actionMessages,
        setActionMessages,
        isSubmittingMessage: isSubmittingMessage,
        isGeneratingResponse: isGeneratingResponse,
        stopGeneratingResponse,
        clearThread,
        postMessage,
    };
};