import React from 'react';
import './styles.css';
import LexIcon from '../../assets/icon.svg';

function Navbar({ actionButtons = [] }) {
    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <img src={LexIcon} alt="Lexagle logo" />
            </div>
            <div className="navbar-separator"></div>
            <div className="navbar-actions">
                {actionButtons.map(({ label, onClick, icon, isDisabled }) => (
                    <button
                        disabled={isDisabled}
                        className="navbar-action-button"
                        onClick={onClick}
                    >
                        {icon}
                        {label}
                    </button>
                ))}
            </div>
        </nav>
    );
}

export default Navbar;
