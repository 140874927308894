
import OpenAI from "openai";
import { runFinishedEvents } from "../constants";

const openai = new OpenAI({
    apiKey: process.env["REACT_APP_LEXAGLE_OPENAI_API_KEY"],
    dangerouslyAllowBrowser: true,
});

const createThread = async (messages = [], onResponseDelta, onCreateMessagesSuccess, onResponseStart, onResponseFinish) => {
    const thread = await openai.beta.threads.create({
        messages,
    });

    const threadMessages = await openai.beta.threads.messages.list(
        thread.id
    );

    onCreateMessagesSuccess?.(threadMessages?.data ?? []);

    const run = openai.beta.threads.runs.createAndStream(thread.id, {
        assistant_id: process.env["REACT_APP_LEXAGLE_OPENAI_ASSISTANT_ID"],
    }).on('messageDelta', (delta, snapshot) => { onResponseDelta?.(snapshot.id, snapshot) })
        .on('event', (params) => {
            const { event, data } = params;

            if (event === 'thread.run.in_progress') {
                onResponseStart?.(data);
            }
            if (runFinishedEvents.includes(event)) {
                onResponseFinish?.(data);
            }
        });

    return { ...run, run_id: run.id, thread_id: thread.id };
}

export const retrieveThreadMessages = async (threadId) => {
    const threadMessages = await openai.beta.threads.messages.list(
        threadId,
        { limit: 50 }
    );

    return threadMessages;
};

export const createMessage = async (threadId, message, attachment, onResponseDelta, onCreateMessagesSuccess, onResponseStart, onResponseFinish) => {
    let file;
    if (attachment) {
        file = await openai.files.create({
            file: attachment,
            purpose: "assistants",
        });
    }

    const messageParams = { role: "user", content: message, file_ids: file ? [file.id] : [] };
    if (!threadId) {
        return createThread([messageParams], onResponseDelta, onCreateMessagesSuccess, onResponseStart, onResponseFinish);
    } else {
        const newMessage = await openai.beta.threads.messages.create(
            threadId,
            messageParams,
        );

        onCreateMessagesSuccess?.(newMessage ? [newMessage] : []);

        const run = openai.beta.threads.runs.createAndStream(threadId, {
            assistant_id: process.env["REACT_APP_LEXAGLE_OPENAI_ASSISTANT_ID"],
        }).on('messageDelta', (delta, snapshot) => { onResponseDelta?.(snapshot.id, snapshot) })
            .on('event', (params) => {
                const { event, data } = params;

                if (event === 'thread.run.in_progress') {
                    onResponseStart?.(data);
                }
                if (runFinishedEvents.includes(event)) {
                    onResponseFinish?.(data);
                }
            });

        return { ...run, run_id: run.id, thread_id: threadId, message: newMessage };
    }

}

export const retrieveRun = async (threadId, runId) => {
    const run = await openai.beta.threads.runs.retrieve(
        threadId,
        runId,
    );

    return { ...run, run_id: run.id };
}

export const retrieveAssistantsFiles = async () => {
    const list = await openai.files.list({ purpose: "assistants" });

    return list.data;
}

export const cancelRun = async (threadId, runId) => {
    const run = await openai.beta.threads.runs.cancel(
        threadId,
        runId,
    );

    return { ...run, run_id: run.id };
}

export const retrieveAllThreadRunSteps = async (threadId) => {
    const runs = await openai.beta.threads.runs.list(
        threadId
    );
    
    const runStepPromises = (runs?.data ?? []).map((run) => retrieveRunSteps(threadId, run.id));
    try {
        const results = await Promise.all(runStepPromises);
        return results.flat();
    } catch (error) {
        console.error('Error during API calls:', error);
    }
}

export const retrieveRunSteps = async (threadId, runId) => {
    const runStep = await openai.beta.threads.runs.steps.list(
        threadId,
        runId,
    );
    return runStep?.data ?? [];
}