// https://platform.openai.com/docs/api-reference/assistants-streaming/events
export const runFinishedEvents = [
    "thread.run.requires_action",
    "thread.run.completed",
    "thread.run.failed",
    "thread.run.cancelled",
    "thread.run.expired",
    "thread.run.step.failed",
    "thread.run.step.cancelled",
    "thread.run.step.expired",
];
